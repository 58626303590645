@import "../../../scss/mixins";
@import "../../../scss/sizes.scss";
@import "../../../scss/constants.scss";

.compare-car__row {
  @include flex;
  .compare-car__row-head {
    min-width: 330px;
    max-width: 330px;
    box-sizing: border-box;
    padding: $space-tier-5 $space-tier-3;
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;
    min-height: 100%;
    border-right: 2px solid #ececec;
    @media screen and (max-width: $media-screen-3) {
      display: none;
      border-right: none;
    }
  }
  .compare-car__row-value {
    min-width: 300px;
    max-width: 300px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: center;
    border-right: 1px solid #ececec;
    & > .head {
      display: none;
    }

    &:last-child {
      border-right: none;
    }
    & > * {
      padding: $space-tier-5 $space-tier-4;
      text-align: center;
      width: 100%;
    }
    &.good {
      font-weight: 400;
    }
    &.empty {
      color: $main-color;
    }
  }
  @media screen and (max-width: $media-screen-3) {
    .compare-car__row-value {
      padding: 20px 0 10px;
      min-width: 200px;
      max-width: 200px;
      border-right: none;
      & > * {
        text-align: left;
        padding: 0 10px;
        box-sizing: border-box;
      }
      &.good {
        font-weight: 500;
      }
    }

    .compare-car__row-value > .head {
      display: block !important;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 300;
      color: #666666;
      position: absolute;
      transform: translateY(-1em);
      left: 20px;
      width: 100%;
      flex-direction: column;
      & > div:first-child {
        position: sticky;
        left: 0;
      }
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}
