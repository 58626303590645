@mixin flex(
  $align: flex-start,
  $justify: flex-start,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: $wrap;
}
@mixin rectangle($size1, $size2) {
  max-width: $size1;
  min-width: $size1;
  min-height: $size2;
  max-height: $size2;
}
@mixin square($size) {
  @include rectangle($size, $size);
}
@mixin circle($size) {
  @include square($size);
  border-radius: 50%;
}
@mixin size16x9($size) {
  min-width: $size;
  max-width: $size;
  min-height: $size / 16 * 9;
  max-height: $size / 16 * 9;
}
