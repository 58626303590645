@import "./assets/fonts/Roboto/Roboto.scss";
@import "./scss/fonts.scss";
@import "./scss/button";
@import "./scss/constants.scss";

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-color;
  * {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  // & > #root {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  // }

  &.disabled-window {
    overflow: hidden;
  }
}
button {
  @extend %base-button;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  @include font-title;
}

.sub-title.sub-title {
  @include font-subtitle-2;
  color: black;
  text-transform: none;
}

body input {
  @include font-inputs;
  padding: 0;
  outline: 0px;
}

body {
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: $main-color;
  }
}
