@import "mixins";
@import "constants.scss";

$base-button-background-color: $main-color;
$base-button-disabled-background-color: #c4c4c4;
$base-button-border-radius: 8px;
$base-button-color: #ffffff;
$base-button-disabled-color: #ffffff;

$button-type-1-background-color: transparent;
$button-type-1-border-color: #c4c4c4;
$button-type-1-border-radius: 8px;
$button-type-1-color: #8f8f8f;

$button-type-2-background-color: #ffffff;
$button-type-2-border-color: $addition-color;
$button-type-2-border-radius: 8px;
$button-type-2-color: $addition-color;

$button-type-3-background-color: #eaeaea;
$button-type-3-border-color: #b6b6b6;
$button-type-3-border-radius: 8px;
$button-type-3-color: #424242;

%button-base-styles {
  @include flex(center, center);
  height: $controll-element-size;
  box-sizing: border-box;
  text-transform: uppercase;
  cursor: pointer;
}
@mixin button-border($color, $radius) {
  border: 2px solid $color;
  border-radius: $radius;
}

%base-button {
  @extend %button-base-styles;
  color: $base-button-color;
  border-radius: $base-button-border-radius;
  background-color: $base-button-background-color;
  text-transform: uppercase;
  border: none;
  &:disabled:disabled {
    color: $base-button-disabled-color;
    background-color: $base-button-disabled-background-color;
  }
}
%button-addition {
  @extend %base-button;
  background-color: $addition-color;
}

%button-type-1 {
  @extend %button-base-styles;
  @include button-border(
    $button-type-1-border-color,
    $button-type-1-border-radius
  );
  color: $button-type-1-color;
  background-color: $button-type-1-background-color;
}

%button-type-2 {
  @extend %button-base-styles;
  @include button-border(
    $button-type-2-border-color,
    $button-type-2-border-radius
  );

  font-size: 14px;
  color: $button-type-2-color;
  background-color: $button-type-2-background-color;
  &:disabled:disabled {
    opacity: 0.3;
    color: $button-type-2-color;
    background-color: $button-type-2-background-color;
  }
}
%button-type-3 {
  @extend %button-base-styles;
  @include button-border(
    $button-type-3-border-color,
    $button-type-3-border-radius
  );
  color: $button-type-3-color;
  background-color: $button-type-3-background-color;
}

%button-action {
  @include flex(center, center);
  @include square($controll-element-size);
  border: 0.5px solid #828282;
  border-radius: 5px;
  font-size: 24px;
  color: #828282;
  min-width: $controll-element-size;
  padding: 0;
  margin-left: $space-tier-5;
  background-color: white;
  img {
    margin: 0;
  }
}
