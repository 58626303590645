// colors
$main-color: #ff5858;
$addition-color: #12bba4;

$gray-color: #b6b6b6;
$gray-border-color: #c4c4c4;
$gray-text-color: #8f8f8f;
$lite-color: #eaeaea;
$body-color: #fafafa;
$dark-color: #454b56;
$disabled-color: #cccccc;
$disabled-color-dark: #888888;

// space
$space-tier-0: 50px;
$space-tier-1: 40px;
$space-tier-2: 30px;
$space-tier-3: 20px;
$space-tier-4: 15px;
$space-tier-5: 10px;
$space-tier-6: 5px;

// border
$radius-tier-1: 8px;
$radius-tier-2: 4px;
$radius-tier-3: 2px;

@mixin border-type-1($color: $gray-border-color, $radius: $radius-tier-1) {
  border: 1px solid $color;
  border-radius: $radius;
}
@mixin border-type-2 {
  border: 1px solid $addition-color;
  border-radius: $radius-tier-1;
}

// shadow
$color-shadow: rgba(0, 0, 0, 0.1);
$color-shadow-dark: rgba(0, 0, 0, 0.3);
$color-shadow-very-dark: rgba(0, 0, 0, 0.5);
$shadow-type-1: 1px 1px 10px $color-shadow;
$shadow-type-2: 0px 2px 5px $color-shadow-dark;
$shadow-type-3: 0 4px 5px $color-shadow-very-dark;
$shadow-type-4: 4px 0px 17px $color-shadow;
$shadow-type-4-reverse-x: -4px 0px 17px $color-shadow;

// animation
$transition-fast: linear 0.1s;
$transition-medium: linear 0.3s;
$transition-slow: linear 0.5s;
$transition-scale: 0.2s transform cubic-bezier(0, 0, 0.2, 1);
$transition-enter-function: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$transition-out-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);

// custom

$border-input-select: 0.5px solid #828282;
$controll-element-size: 40px;
