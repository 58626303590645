$header-size: 80px;
$footer-size: 80px;
$controll-element-size: 40px;

$media-screen-1: 1919px;
$media-screen-2: 1365px;
$media-screen-3: 1023px;
$media-screen-4: 479px;
$media-screen-5: 359px;

.max-width {
  margin: auto;
  max-width: 1570px;
  @media screen and (max-width: $media-screen-1) {
    max-width: 1125px;
  }
  @media screen and (max-width: $media-screen-2) {
    max-width: 950px;
  }
  @media screen and (max-width: $media-screen-3) {
    max-width: 100%;
  }
}

@mixin rectangle($size1, $size2) {
  max-width: $size1;
  min-width: $size1;
  min-height: $size2;
  max-height: $size2;
}
@mixin square($size) {
  @include rectangle($size, $size);
}
@mixin circle($size) {
  @include square($size);
  border-radius: 50%;
}
@mixin size16x9($size) {
  min-width: $size;
  max-width: $size;
  min-height: $size / 16 * 9;
  max-height: $size / 16 * 9;
}
