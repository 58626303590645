@import "../../../scss/mixins";
@import "../../../scss/classes";

.compare-car__empty {
  @include flex;
  flex-direction: column;
  & > .sub-title {
    margin: $space-tier-3 0;
    width: 100%;
    font-size: 24px;
  }
}
